import styled from 'styled-components'
import React from 'react'

import { Input } from './Input'
import { palette } from '../../lib/cssHelpers'
import { shade } from '../../lib/helpers'
import { parseDate } from '../../lib/timeHelpers'

interface IconProps {
  disabled?: boolean
}
export const Icon = styled.img`
  height: 25px;
  width: 25px;
  cursor: pointer;
  pointer-events: ${(p: IconProps) => (p.disabled ? 'none' : 'auto')};
  opacity: ${(p: IconProps) => (p.disabled ? 0.5 : 1)};
`

export const Table = styled.table`
  width: 100%;
  margin: 10px 0;
  table-layout: fixed;
`

interface HeaderProps {
  dark?: boolean
}

export const Header = styled.span`
  text-transform: uppercase;
  font-size: 0.65rem;
  color: ${(p: HeaderProps) => (p.dark ? palette.textDarkBg : palette.muted)};
  border: 1px solid ${shade(palette.muted, 0.8)};
  border-radius: 10rem;
  padding: 0 0.75rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  line-height: 0;
  max-width: 100%;
  overflow: hidden;
  background-color: ${(p: HeaderProps) => (p.dark ? 'black' : 'white')};
`

interface HeaderCellProps {
  noBorder?: boolean
}

export const HeaderCell = styled.th`
  border-bottom: ${(p: HeaderCellProps) => (p.noBorder ? '' : `1px solid ${shade(palette.muted, 0.8)}`)};
  padding: 0.75rem 0.25rem;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
`

export const Cell = styled.td`
  padding: 0.75rem 0.5rem;
`

export const NarrowCell = styled.td`
  padding: 0.1rem 0.5rem;
`

export const Row = styled.tr`
  border-bottom: 1px solid ${shade(palette.muted, 0.8)};
`

export const EditableCell = (props: any) => {
  const {
    value: initialValue,
    row: { index },
    column: { id },
    update,
  } = props

  const [value, setValue] = React.useState(initialValue)
  const onChange = (e: any) => setValue(e.target.value)
  const onBlur = () => update && update(index, id, value)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <Input
      style={{ margin: '0', boxSizing: 'border-box' }}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={props.column.Header}
      value={value || ''}
    />
  )
}

export const EditableCheckbox = (props: any) => {
  const {
    value: initialValue,
    row: { index },
    column: { id },
    update,
  } = props

  const [checked, setChecked] = React.useState(initialValue)

  const onChange = () => {
    setChecked(!checked)
    update(index, id, !checked)
  }

  React.useEffect(() => {
    setChecked(initialValue)
  }, [initialValue])

  return <input onChange={onChange} checked={checked} type="checkbox" />
}

export const TextCell = (props: any) => {
  const value = props.column.isDate ? parseDate(props.value) : props.value
  return <p className="m-0 text-m">{value}</p>
}

export const SmallTextCell = (props: any) => {
  const value = props.column.isDate ? parseDate(props.value) : props.value
  return <p className="m-0 text-s">{value}</p>
}

interface NoResultsProps {
  message: string
  columns?: number
}

export const isColumn = (data: any, header: string) => (data.Header || data.column.Header) === header

export const NoResults = (props: NoResultsProps) => (
  <tr>
    <td colSpan={props.columns || 1}>
      <p className="text-m text-center my-3 text-muted font-italic">{props.message}</p>
    </td>
  </tr>
)

export const defaultColumn = {
  Cell: TextCell,
}
